/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import ShineImg from 'img/shine.png'
import {
  ParseContent,
  Image,
  ImageBackground,
  SEO,
  ContainerLeft,
} from 'components/shared'

// Components
import Layout from 'components/layout/Layout'
import HeroImg from 'img/hero5.png'

// Blog
import Blog from 'components/related/Blog'
import Blogik, { BlogConsumer, BlogButton } from 'components/shared/Blogik'

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => (
  <Layout>
    <SEO yoast={yoast} path={path} type="article" />

    <ShineImage>
      <Image src={ShineImg} style={{ width: '100%' }} />
    </ShineImage>

    <Hero fields={acf.story} />

    <div className="container">
      <BlogContent content={acf.story.description} />
    </div>

    <div className="container mt-3">
      <BlogHeading>Ook interessant</BlogHeading>
    </div>

    {/* BLOGS */}
    <div className="pb-5">
      <Blogik
        settings={{
          limit: null,
        }}
      >
        <BlogConsumer>
          {({ hasPosts, posts, showMoreButton, showLessButton }) => (
            <div className="py-lg-5 py-3">
              {hasPosts && (
                <div className="container py-lg-5 py-3">
                  <Blog posts={posts} />
                </div>
              )}

              <div className="text-center mt-5">
                {showMoreButton && <BlogButton>Meer tonen</BlogButton>}
                {showLessButton && (
                  <BlogButton increment={false}>Minder tonen</BlogButton>
                )}
              </div>
            </div>
          )}
        </BlogConsumer>
      </Blogik>
    </div>

    <div className="py-4" />
  </Layout>
)

const BlogHeading = styled.h3`
  margin-top: 150px;
  font-family: ${(props) => props.theme.font.family.main};
  font-size: 40px;

  @media screen and (max-width: 768px) {
    margin-top: 70px;
    font-size: ${(props) => props.theme.font.size.xl};
  }
`

const BlogContent = styled(ParseContent)`
  background: white;
  font-size: ${(props) => props.theme.font.size.sm};
  color: ${(props) => props.theme.color.text.main};
  font-family: ${(props) => props.theme.font.family.secondary};
  position: relative;
  margin-top: -100px;
  line-height: 28px;

  & h2,
  & h3,
  & h4,
  & h5 {
    font-family: ${(props) => props.theme.font.family.main};
    font-size: ${(props) => props.theme.font.size.m};
    font-weight: ${(props) => props.theme.font.weight.l};
    margin-top: 2.5rem;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    margin-bottom: 0;
    line-height: 27px;
  }

  @media (min-width: 992px) {
    padding: 72px 100px 100px 100px;
  }

  @media (max-width: 991px) {
    padding: 1.5rem;
  }

  @media screen and (max-width: 456px) {
    font-size: ${(props) => props.theme.font.size.s};
  }
`

const HeroContainer = styled.div`
  position: relative;
  margin-top: 0px;
`

const Content = styled(ParseContent)``

const Hero = ({ fields }) => (
  <HeroContainer className="d-flex flex-wrap">
    <div className="w-100 w-lg-50 mb-5 mb-lg-0 pr-xl-0 pr-5 pt-5 pt-lg-0">
      <HeroContent>
        <Content content={fields.title} />
      </HeroContent>
    </div>
    <div className="w-100 w-lg-50">
      <HeroImage
        src={fields.image}
        style={{ width: '100%', height: '100%', minHeight: 500 }}
      />
    </div>
  </HeroContainer>
)

const HeroImage = styled(ImageBackground)`
  width: 100%;
  
  @media (min-width: 992px) {
    height: 100%;
    min-height: 500px;
  }

  @media (max-width: 991px) {
    padding-top: 50%;
    min-height: 300px;
    max-height: 400px;
  }
`

const HeroContent = styled(ContainerLeft)`
  color: ${(props) => props.theme.color.text.light};
  
  p {
    font-family: ${(props) => props.theme.font.family.main};
    letter-spacing: 3px;
  }

  h1 {
    font-family: ${(props) => props.theme.font.family.main};
    color: ${(props) => props.theme.color.face.light};

    @media (min-width: 1200px) {
      font-size: ${(props) => props.theme.font.size.xl};
      line-height: ${(props) => props.theme.font.size.xxl};
    }

    @media (max-width: 1199px) {
      font-size: ${(props) => props.theme.font.size.l};
      line-height: ${(props) => props.theme.font.size.xl};
    }
  }

  .blog {
    font-size: ${(props) => props.theme.font.size.m};
    color: ${(props) => props.theme.color.face.secondary};
  }

  @media (min-width: 992px) {
    margin-top: 120px;
  }
`

const ShineImage = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`

export default PageTemplate

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPost(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content

      acf {
        story {
          title
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`