import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

// Elements
import LinkButton from 'components/elements/LinkButton'

// Shared
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'


const Content = styled(ParseContent)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  
  @media (min-width: 1200px) {
    -webkit-line-clamp: 15;
    height: 360px;
  }

  @media (max-width: 1199px) {
    -webkit-line-clamp: 12;
    height: 290px;
  }
`

const Title = styled.h4`
  height: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
`

export default function Blog({ fields }) {
  return (
    <BlogContainer>
      <Link to={fields.path}>
        <ImageContainer>
          <BlogImage src={fields.acf.story.image} />
          <Time>{fields.acf.preview.readtime}</Time>
        </ImageContainer>
      </Link>
      
      <BlogContent className="p-4">
        <Title className="pb-3">{fields.title}</Title>
        <Content content={fields.acf.preview.description} />
        <div className="py-lg-3 py-2" />
        <LinkButton to={fields.path} title="Lees verder" />
      </BlogContent>
    </BlogContainer>
  )
}

const BlogContainer = styled.div`
  @media (min-width: 1200px) {
    min-width: 350px;
    max-width: 450px;
  }

  @media (max-width: 1199px) {
    min-width: 300px;
    max-width: 350px;
  }

  @media (max-width: 991px) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`

const Time = styled.div`
  background: ${(props) => props.theme.color.face.secondary};
  color: ${(props) => props.theme.color.text.main};
  font-size: ${(props) => props.theme.font.weight.s};
  font-family: ${(props) => props.theme.font.family.main};
  padding: 20px 18px 28px 8px;
  border-bottom-right-radius: 100px;
  text-transform: uppercase;
  position: absolute;
  top: 0;
`

const ImageContainer = styled.div`
  height: 250px;
  position: relative;
  background: ${(props) => props.theme.color.face.gray};
`

const BlogImage = styled(Image)`
  height: 100%;
`

const BlogContent = styled.div`
  background: ${(props) => props.theme.color.face.light};
  h4 {
    font-size: ${(props) => props.theme.font.size.l};
    color: ${(props) => props.theme.color.text.main};
    font-family: ${(props) => props.theme.font.family.main};
    min-height: 130px;
  }
  p {
    font-size: ${(props) => props.theme.font.size.sm};
    font-weight: ${(props) => props.theme.font.weight.m};
    line-height: ${(props) => props.theme.font.size.l};
    color: ${(props) => props.theme.color.text.main};
    font-family: ${(props) => props.theme.font.family.secondary};
  }
  @media screen and (max-width: 768px) {
    p {
      font-weight: ${(props) => props.theme.font.weight.m};
    }
  }
`
