/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-cycle */
import React from 'react'
import styled from 'styled-components'

// Shared
import {
  ContainerRight,
  ImageBackground,
  ParseContent,
} from 'components/shared'

// Images
import BackImg from 'img/back1.png'

const StyledCustomerCase = styled.div`
  @media screen and (max-width: 991px) {
    margin-bottom: 50px;
  }
`

const StyledContainerRight = styled(ContainerRight)`
  margin-right: 0;
`

export default function CustomerCase({ fields }) {
  return (
    <StyledCustomerCase className="row">
      <BackImageContainer className="col-lg-6 position-relative d-flex align-items-center justify-content-center justify-xl-content-end">
        <ParseContent content={fields.quote || ''} />
      </BackImageContainer>
      <CardContainer className="col-lg-6 mt-5 mt-lg-0">
        <div className="w-100 d-flex justify-content-lg-end justify-content-center">
          <StyledContainerRight>
            <Card className="d-flex">
              <CardImage src={BackImg} />
              <CardContent className="d-flex flex-column">
                <ParseContent content={fields.description} />
              </CardContent>
            </Card>
          </StyledContainerRight>
        </div>
      </CardContainer>
    </StyledCustomerCase>
  )
}

const BackImageContainer = styled.div`
  position: relative;
  z-index: 0;
  overflow: hidden;

  & h4 {
    @media (min-width: 1200px) {
      font-size: ${(props) => props.theme.font.size.xxl};
    }

    @media (max-width: 1199px) {
      font-size: ${(props) => props.theme.font.size.xl};
    }

    @media (max-width: 992px) {
      font-size: ${(props) => props.theme.font.size.l};
    }
  }

  @media (min-width: 992px) {
    width: 50%;
    min-height: 700px;
  }

  @media (max-width: 991px) {
    width: 100%;
  }
`

const CardContainer = styled.div`
  right: 0;
  display: flex;
  flex-wrap: wrap;
  z-index: 3;

  @media screen and (max-width: 991px) {
    position: relative;
    margin: 0 auto;
  }

  @media screen and (max-width: 575px) {
    margin-top: -100px;
  }
`

const CardImage = styled(ImageBackground)`
  position: absolute !important;
  background-repeat: no-repeat !important;
  width: 200px;
  height: 220px;
  background-size: contain;
  background-position-x: 0;
  background-position-y: 0;
`

const CardContent = styled.div`
  z-index: 1;

  .dot {
    color: ${(props) => props.theme.color.face.secondary};
  }

  p {
    strong, b {
      font-weight: 600;

      @media (min-width: 992px) {
        font-size: ${(props) => props.theme.font.size['18']};
      }

      @media (max-width: 991px) {
        font-size: ${(props) => props.theme.font.size.m};
      }
    }
  }

  @media screen and (max-width: 456px) {
    margin-left: 30px;
  }

  .sub {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: ${(props) => props.theme.font.size.m};
    margin-top: 26px;
  }

  @media (min-width: 992px) {
    margin-top: 80px;
    margin-left: 60px;
  }

  @media (max-width: 991px) {
    margin-top: 50px;
    margin-left: 30px;
  }
`

const Card = styled.div`
  background: white;
  position: relative;
  border-top-right-radius: 100px;
  color: ${(props) => props.theme.color.text.main};
  padding: 30px 60px 30px 0;

  h4 {
    font-size: ${(props) => props.theme.font.size.xl};
    font-family: ${(props) => props.theme.font.family.main};
    min-height: 130px;
  }

  p {
    font-size: ${(props) => props.theme.font.size.sm};
    font-weight: ${(props) => props.theme.font.weight.m};
    line-height: ${(props) => props.theme.font.size.l};
    font-family: ${(props) => props.theme.font.family.secondary};
  }

  @media (min-width: 992px) {
    top: 50px;
    min-height: 620px;
  }

  @media (min-width: 576px) {
    width: 500px;
  }

  @media screen and (max-width: 425px) {
    padding: 40px 30px 40px 0px;
  }

  @media screen and (max-width: 375px) {
    padding: 40px 30px 40px 0px;
  }

  @media screen and (max-width: 320px) {
    padding: 40px 30px 40px 0px;
  }
`
